import api from "api"
import { AuthData } from "api/resources/users/types"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import {
  getAuthInfo,
  getProbablePlatform,
  isValidOrigin,
  savePlatformAuthInfo,
} from "utils/auth"
import {
  ADMISSIONS_PLATFORM_URL,
  CENTRE_PLATFORM_URL,
  IQA_PLATFORM_URL,
  LEARN_PLATFORM_URL,
  PARTNER_PLATFORM_URL,
  Platforms,
  Product,
  PROF_PLATFORM_URL,
  SCHOOL_PLATFORM_URL,
  SVP_PLATFORM_URL,
} from "utils/constants"
import { trackUser } from "utils/helpers"
import toast from "utils/toast"

export const redirect = (product: Product, platform?: Platforms | null) => {
  const probablePlatform = getProbablePlatform(product, platform)

  const goToPlatform = (base: string) => {
    const url = new URL(base)
    url.search = window.location.search
    window.location.href = url.toString()
  }

  switch (probablePlatform) {
    case Platforms.student:
      goToPlatform(LEARN_PLATFORM_URL)
      return
    case Platforms.centerHead:
      goToPlatform(CENTRE_PLATFORM_URL)
      return
    case Platforms.centreAdmin:
      goToPlatform(CENTRE_PLATFORM_URL)
      return
    case Platforms.professor:
      goToPlatform(PROF_PLATFORM_URL)
      return
    case Platforms.serviceAdmin:
      throw new Error("Invalid")
    // goToPlatform(LEARN_PLATFORM_URL)
    case Platforms.iqa:
      goToPlatform(IQA_PLATFORM_URL)
      return
    case Platforms.svp:
      goToPlatform(SVP_PLATFORM_URL)
      return
    case Platforms.school:
      goToPlatform(SCHOOL_PLATFORM_URL)
      return
    case Platforms.apiDocs:
      throw new Error("Invalid")
    // goToPlatform(LEARN_PLATFORM_URL)
    case Platforms.olympiadPartner:
      goToPlatform(PARTNER_PLATFORM_URL)
      return
    case Platforms.applicationFormAdmin:
      goToPlatform(ADMISSIONS_PLATFORM_URL)
      return
    case Platforms.admissionsCounsellor:
    case Platforms.recruitmentUtils:
      throw new Error("redirect-url must be supplied for this platform")

    default:
      throw new Error("This shouldn't happen")
  }
}

type Props = {
  options: {
    initialLoading?: boolean
    processRedirect?: boolean
    onAuthCodeGen?: (code: string) => void
    iframe?: boolean
  }
  product: Product
  platform: Platforms
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useHybridLogin = ({ options, product, platform }: Props) => {
  // We want to process redirect-url by default
  if (options.processRedirect === undefined) {
    options.processRedirect = true
  }

  const [loading, setLoading] = useState(options.initialLoading || false)

  const [searchParams] = useSearchParams()

  const origin = searchParams.get("origin")

  const processRedirectURL = (code: string) => {
    const redirectUrl = searchParams.get("redirect-url")

    if (redirectUrl) {
      try {
        const url = new URL(redirectUrl)
        url.searchParams.append("code", code)
        window.location.href = url.href
      } catch {
        redirect(product, platform)
      }
    } else {
      redirect(product, platform)
    }
  }
  const generateAuthCode = async (platformKey: Platforms) => {
    setLoading(true)
    const res = await api.users.generateAuthCode({
      data: { platform: platformKey },
    })
    if (res.isSuccessful) {
      const { code } = res.data

      if (options.processRedirect) {
        processRedirectURL(code)
      } else {
        options.onAuthCodeGen?.call(this, code)
      }
      if (options.iframe) {
        if (origin && isValidOrigin(origin))
          window.parent.postMessage({ code }, origin)
      }
    } else {
      if (res.errors.message) {
        toast.error(res.errors.message)
      }
      setLoading(false)
    }
  }

  const onLoginSuccess = async (data: Omit<AuthData, "platform">) => {
    savePlatformAuthInfo(platform, data)
    trackUser(data)
    await generateAuthCode(platform)
  }

  useEffect(() => {
    const handleLogin = () => {
      const auth = getAuthInfo(platform)
      /**
       * We allow user to open Student sign in page if they provide platform=Student.
       * If they don't then we just redirect them to the appropriate portal according to the platform present
       * in the `auth` object.
       */
      if (auth) {
        return generateAuthCode(platform)
      }
      setLoading(false)
    }

    handleLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { onLoginSuccess, loading }
}
